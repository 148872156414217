import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { isolatedHeader } from 'Addons/fitmentSearch/isolatedKeys.ts';
import VehicleWidget from 'Components/fitmentSearch/vehicleWidget/index.ts';
import { getVehicleRedirectUrl } from 'Core/epics/fitmentSearch/redirect.js';
import useScroll from 'Core/hooks/horizontalScroll.tsx';
import { createSelectedVehicleSelectionSelector } from 'Core/selectors/fitmentSearch/index.js';
import { prepareResponseFacets } from 'Core/reducers/search/response.js';
import fitmentSearchConfig from 'Models/uiConfig/fitmentSearchConfig.js';
import requestConfig from 'Models/uiConfig/requestConfig.js';
import { getVehicleFromUri } from 'Modules/converter/index.js';
import { facets } from 'Modules/serverApi/index.js';
import { cloneSafe } from 'Utils/components.ts';
import Tile from './tile.tsx';
import Subtile from './subtile.ts';

import type { FunctionComponent } from 'react';
import type {
  RepeaterFunctionInvoker,
  TemplateFunction,
  TemplateFunctionInvoker,
  TemplateResult,
} from 'Components/types.ts';
import type { UseScroll } from 'Core/hooks/horizontalScroll.tsx';
import type { Facet, FacetRequest, FacetValueFull } from 'Models/index.ts';
import type { ServerModel } from 'Modules/serverApi/types.ts';
import type { Params as TileParams, SubvalueParams } from './tile.tsx';

interface LetterTileParams extends LetterParams {
  tiles: RepeaterFunctionInvoker<TileParams>;
}
interface FilterLetterParams extends LetterParams {
  currentLetter: string;
  isDisabled: boolean;
  setCurrentLetter: (letter: string) => void;
}
interface LetterParams {
  letter: string;
}

type Params = {
  alphabeticalGroups: RepeaterFunctionInvoker<LetterParams>;
  alphabeticalLetters: RepeaterFunctionInvoker<LetterParams>;
  currentLetter: string;
  hierarchicalGroups: RepeaterFunctionInvoker<TileParams>;
  vehicleWidget: TemplateFunctionInvoker<unknown>;
  isMultiLevel?: boolean;
  view?: string;
} & UseScroll;

type Props = {
  template: TemplateFunction<Params>;
  columnBreakpoint?: number;
  excludeFieldPreselection?: string;
  facetField?: string;
  initCollapsed?: boolean;
  isAlwaysActive?: boolean;
  isMultiLevel?: boolean;
  isVehicleWidgetDisabled?: boolean;
  view?: string;
  useNativeDropdown?: boolean;
};

const FacetTiles: FunctionComponent<Props> = ({
  template,
  columnBreakpoint,
  excludeFieldPreselection,
  facetField = fitmentSearchConfig.currentCategorySelectionPageField as string,
  initCollapsed,
  isAlwaysActive,
  isMultiLevel,
  isVehicleWidgetDisabled,
  view,
  useNativeDropdown,
}) => {
  const rootRef = useRef<HTMLElement>(null);
  const [tilesFacet, setTilesFacet] = useState<Facet>();
  const { scrollLeft, scrollRight, isLeftBorder, isRightBorder } = useScroll(rootRef);

  const selectedVehicleSelection = useSelector(createSelectedVehicleSelectionSelector);
  const vehicleFromUriSelection = useMemo(() => getVehicleFromUri().selection, []) as FacetRequest[];

  const [currentLetter, setCurrentLetter] = useState<string>('All');
  const alphabeticalGroups = useGroupAlphabetically(tilesFacet);

  const [currentTab, setCurrentTab] = useState<string | null>(null);
  const hierarchicalGroups = useGroupHierarchically(tilesFacet);

  useEffect(() => {
    if (!currentTab && hierarchicalGroups.length) {
      setCurrentTab(hierarchicalGroups[0][0].value);
    }
  }, [currentTab, hierarchicalGroups]);

  useEffect(() => {
    const vehicleSelection = vehicleFromUriSelection.length
      ? vehicleFromUriSelection
      : fitmentSearchConfig.isFitmentSearchWidgetsVisible || !isVehicleWidgetDisabled
        ? selectedVehicleSelection
        : [];

    const preselection = [
      ...vehicleSelection,
      ...requestConfig.localPreselection,
      ...requestConfig.defaultSelection,
    ]
      .filter((v) => v.field !== excludeFieldPreselection)
      .map((v: FacetRequest) => {
        return { ...v, hidden: v.field !== facetField };
      });

    const facetSelection = { field: facetField, expand: isMultiLevel };

    const request = [...preselection, facetSelection];

    facets(request).then((response: ServerModel.FacetsResponse) => {
      const precessedResponse = prepareResponseFacets(
        requestConfig.facetResponseHandler(response).Facets,
        [],
      );

      const tilesFacet = precessedResponse?.get(facetField);

      if (tilesFacet?.values?.length) {
        setTilesFacet(tilesFacet);
      } else if (!requestConfig.hasSearchResults) {
        window.location.assign(getVehicleRedirectUrl(vehicleSelection));
      }
    });
  }, [
    excludeFieldPreselection,
    facetField,
    isMultiLevel,
    isVehicleWidgetDisabled,
    selectedVehicleSelection,
    vehicleFromUriSelection,
  ]);

  const vehicleWidget: TemplateFunctionInvoker<unknown> = (templ) => {
    const props = {
      isAlwaysActive: isAlwaysActive ?? false,
      initCollapsed,
      isHidden: isVehicleWidgetDisabled,
      columnBreakpoint,
      name: 'FacetTilesVehicleWidget',
      isolatedKey: !requestConfig.hasSearchResults ? isolatedHeader : null,
      useNativeDropdown,
      template: templ,
    };
    return (<VehicleWidget {...props} key="VehicleWidget" />) as TemplateResult;
  };

  const alphabeticalLetters = alphabeticalGroups.map(
    ([letter, values]) =>
      (templ) =>
        cloneSafe(
          templ.call({
            currentLetter,
            isDisabled: letter !== 'All' && !values.length,
            letter,
            setCurrentLetter,
          }),
          null,
          { key: `filterLetter-${letter}` },
        ),
  ) as RepeaterFunctionInvoker<FilterLetterParams>;

  const filteredAlphabeticalGroup = useFilterGroup(currentLetter, alphabeticalGroups, ([letter]) => letter);
  const filteredHierarchicalGroups = useFilterGroup(
    currentLetter,
    hierarchicalGroups,
    ([facetValue]) => facetValue.term,
  );

  const alphabeticalGroupsRepeater = filteredAlphabeticalGroup.map(([letter, values]) => (templ) => {
    const tilesRepeater = values.map((value) => (templ) => {
      const props = { template: templ, subvalue: value, vehicleFromUriSelection };
      return <Subtile {...props} key={value.term} />;
    }) as RepeaterFunctionInvoker<SubvalueParams>;

    const component = templ.call({ letter, tiles: tilesRepeater });
    return cloneSafe(component, null, { key: letter });
  }) as RepeaterFunctionInvoker<LetterTileParams>;

  const hierarchicalGroupsRepeater = filteredHierarchicalGroups.map(([value, subvalues]) => (templ) => {
    const props = {
      template: templ,
      value,
      subvalues,
      currentTab,
      isMultiLevel,
      vehicleFromUriSelection,
      setCurrentTab,
      vehicleWidget,
    };
    return <Tile {...props} key={value.term} />;
  }) as RepeaterFunctionInvoker<TileParams>;

  const component = template.call({
    alphabeticalGroups: alphabeticalGroupsRepeater,
    alphabeticalLetters,
    currentLetter,
    hierarchicalGroups: hierarchicalGroupsRepeater,
    isMultiLevel,
    view,
    vehicleWidget,
    scrollLeft,
    scrollRight,
    isLeftBorder,
    isRightBorder,
  });

  return cloneSafe(component, rootRef);
};

const charArray = ['All', '#', ...'ABCDEFGHIJKLMNOPQRSTUVWXYZ'];

function getFilterChar(str: string) {
  const firstChar = str.charAt(0).toUpperCase();
  return charArray.includes(firstChar) ? firstChar : '#';
}

function useFilterGroup(currentLetter: string, group, keySelector: (value: unknown) => string) {
  return currentLetter !== 'All'
    ? group.filter((value) => getFilterChar(keySelector(value)) === currentLetter)
    : group;
}

type AlphabeticalFacet = [string, FacetValueFull[]][];
function useGroupAlphabetically(facet: Facet | undefined): AlphabeticalFacet {
  if (!facet?.values) {
    return [];
  }

  return facet.values.reduce(
    (groups, facetValue) => {
      const keyChar = getFilterChar(facetValue.term);
      const groupIndex = groups.map((group) => group[0]).indexOf(keyChar);

      if (groupIndex > 0) {
        (groups[groupIndex][1] as FacetValueFull[]).push(facetValue);
      }

      return [...groups];
    },
    charArray.map((char) => [char, []]),
  ) as AlphabeticalFacet;
}

type FacetSubvaluesPairs = [FacetValueFull, FacetValueFull[]][];
function useGroupHierarchically(facet: Facet | undefined): FacetSubvaluesPairs {
  if (!facet?.values) {
    return [];
  }

  return facet.values.reduce((pairs, facetValue) => {
    switch (facetValue.treeLevel) {
      // not IsTree facet or first level value
      case null:
      case 0:
        pairs.push([facetValue, []]);
        break;
      // subvalue
      default: {
        const value = pairs[pairs.length - 1];

        // there are subvalues but no parent value
        if (value && !value[0].term.includes('>')) {
          // so far only the second level is supported
          if (facetValue.treeLevel === 1) {
            pairs[pairs.length - 1][1].push(facetValue);
          }
        } else {
          pairs.push([facetValue, []]);
        }
        break;
      }
    }
    return pairs;
  }, [] as FacetSubvaluesPairs);
}

export default FacetTiles;
