import { useEffect, useState, type RefObject } from 'react';

export type UseScroll = {
  scrollLeft: () => void;
  scrollRight: () => void;
  isLeftBorder: boolean;
  isRightBorder: boolean;
};

export default function useScroll(rootRef: RefObject<HTMLElement | undefined>): UseScroll {
  const [isLeftBorder, setIsLeftBorder] = useState(true);
  const [isRightBorder, setIsRightBorder] = useState(true);

  const root = rootRef.current;
  const elem = root?.querySelector('[data-cm-scroll-container]');

  useEffect(
    function updateBorders() {
      if (elem instanceof HTMLElement) {
        const update = () => {
          setIsLeftBorder(elem.scrollLeft === 0);
          setIsRightBorder(elem.scrollLeft + elem.clientWidth >= elem.scrollWidth);
        };

        update();
        elem.addEventListener('scroll', update);
        return () => elem.removeEventListener('scroll', update);
      }
    },
    [elem],
  );

  if (!root || !elem || !(elem instanceof HTMLElement)) {
    return { scrollLeft: () => {}, scrollRight: () => {}, isLeftBorder, isRightBorder };
  }

  const scrollLeft = () => elem.scrollBy({ left: -elem.clientWidth, behavior: 'smooth' });
  const scrollRight = () => elem.scrollBy({ left: elem.clientWidth, behavior: 'smooth' });

  return { scrollLeft, scrollRight, isLeftBorder, isRightBorder };
}
