import { useDispatch } from 'react-redux';

import { toggle } from 'Core/actions/request.js';
import { cloneSafe } from 'Utils/components.ts';
import { getMinMaxFromTerm, rangeFiniteness } from 'Utils/ranged.ts';

export default function SimpleValue({
  template,
  value,
  isSingleValue,
  value: { isRanged, isUnique, field, term, isSelected, treeLevel },
}) {
  const dispatch = useDispatch();
  const range = isRanged && getMinMaxFromTerm(term);

  const facetDivClass = [isSelected && 'selected', treeLevel && `cm_padding-${treeLevel}`]
    .filter(Boolean)
    .join(' ');

  const params = {
    ...value,
    facetDivClass,
    range: (range || []).map((v) => +v),
    isCustomRange: isUnique,
    ...rangeFiniteness(range || ['0', '0']),
  };
  const component = template.call(params);
  return cloneSafe(component, null, {
    onClick: (e) => {
      if (!onClickPrevented(e, component)) {
        dispatch(toggle({ field, term }, { isSingleValue, mayDiscardValue: true }));
      }
    },
  });
}

function onClickPrevented({ nativeEvent }, { props: { onClick } }) {
  if (typeof onClick === 'function') {
    onClick(nativeEvent);
    return nativeEvent.defaultPrevented;
  }
  return false;
}
